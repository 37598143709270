function Form({ lang }) {
  const url =
    lang === "en"
      ? "//www.123contactform.com/js-form-username-2993265.html?ref=https%3A%2F%2Fwww.dragutship.com%2Fcontact-us.html&amp;_referrer_=https%3A%2F%2Fwww.dragutship.com%2Fglossary.html&amp;_embedType_=embed.js&amp;_iframeID_=1693399637442_316003619442188"
      : "//www.123contactform.com/js-form-username-2993357.html?ref=https%3A%2F%2Fwww.dragutship.com%2Ftr%2Filetisim.html&amp;_referrer_=https%3A%2F%2Fwww.dragutship.com%2Ftr%2Fhakkimizda.html&amp;_embedType_=embed.js&amp;_iframeID_=1693390724220_749543275760993";

  return (
    <iframe
      id="1693322143393_378536869083621"
      src={url}
      style={{
        padding: 0,
        margin: 0,
        display: "block",
        width: "100%",
        backgroundColor: "transparent",
        overflow: "hidden",
        height: 988,
      }}
      frameborder="0"
      scrolling="no"
    ></iframe>
  );
}

export default Form;
