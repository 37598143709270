import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import {
  aboutEn,
  aboutTr,
  researchTr,
  researchEn,
  teamEn,
  teamTr,
  servicesEn,
  servicesTr,
  footerEn,
  footerTr,
  formEn,
  formTr,
  buyAreaEn,
  buyAreaTr,
  heroEn,
  heroTr,
} from "../assets/lang";

const resources = {
  tr: {
    translation: {
      home: "Anasayfa",
      aboutUs: "Hakkımızda",
      services: "Hizmetler",
      research: "Araştırma",
      fleet: "Filo",
      links: "Bağlantılar",
      glossary: "Terimler",
      team: "Ekip",
      contact: "İletişim",
      map: "Site Haritası",
      shippingTerms: "Denizcilik Terimleri",
      shipRent: "Gemi Kiralama Kısaltmaları",
      gallery: "Galeri",
      ...aboutTr,
      ...researchTr,
      ...teamTr,
      ...servicesTr,
      ...footerTr,
      ...formTr,
      ...buyAreaTr,
      ...heroTr,
    },
  },
  en: {
    translation: {
      home: "Home",
      aboutUs: "About Us",
      services: "Services",
      research: "Research",
      fleet: "Fleet",
      links: "Links",
      glossary: "Glossary",
      team: "Team",
      contact: "Contact Us",
      map: "Sıte Map",
      shippingTerms: "Shipping Terms",
      shipRent: "CHARTERING ABBREVIATIONS - TERMS & DEFINITIONS ",
      gallery: "Gallery",
      ...aboutEn,
      ...researchEn,
      ...teamEn,
      ...servicesEn,
      ...footerEn,
      ...formEn,
      ...buyAreaEn,
      ...heroEn,
    },
  },
};

i18n.use(initReactI18next).init({
  lng: "en",
  resources,
});
