/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-target-blank */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

library.add(fab);

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer class="dark_footer_area">
      <div class="d_footer_top_area">
        <div class="footer_widget_area">
          <div class="container">
            <div class="row">
              <div class="col-md-3 col-sm-3">
                <aside
                  class="f_widget widget1 service_w"
                  style={{ height: 338, textTransform: "uppercase" }}
                >
                  <div class="f_title">
                    <h3>{t("map").toLocaleUpperCase()}</h3>
                  </div>
                  <ul>
                    <li>
                      <Link to="/">{t("home")}</Link>
                    </li>
                    <li>
                      <Link to="/about-us">{t("aboutUs")}</Link>
                    </li>
                    <li>
                      <Link to="/services">
                        {t("services").toUpperCase()}
                      </Link>
                    </li>
                    <li>
                      <Link to="/research">{t("research")}</Link>
                    </li>
                    <li>
                      <Link to="fleet">{t("fleet").toLocaleUpperCase()}</Link>
                    </li>
                    <li>
                      <Link to="/links">{t("links")}</Link>
                    </li>
                    <li>
                      <Link to="/glossary">
                        {t("glossary").toLocaleUpperCase()}
                      </Link>
                    </li>
                    <li>
                      <Link to="/team">{t("team").toLocaleUpperCase()}</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">{t("contact")}</Link>
                    </li>
                  </ul>
                </aside>
              </div>
              <div class="col-md-3 col-sm-3">
                <aside
                  class="f_widget widget1 service_w"
                  style={{ height: 338 }}
                >
                  <div class="f_title">
                    <h3 style={{ textTransform: "uppercase" }}>
                      {t("turkeyContact")}
                    </h3>
                  </div>
                  <ul class="address_link" style={{ color: "#888890" }}>
                    <li>
                      <span>{t("address")} :</span> NISH ISTANBUL RESIDENCES. B
                      Blok. No:145 Cobancesme Mah. Sanayi Cd. 34196 Bahcelievler
                      / ISTANBUL / TURKEY
                    </li>
                    <li>
                      <span>{t("phone")} :</span>
                      <a href="#"> +90 212 653 3428</a>
                    </li>
                  </ul>
                </aside>
              </div>
              <div class="col-md-3 col-sm-3" style={{ color: "#888890" }}>
                <aside
                  class="f_widget widget1 service_w"
                  style={{ height: 338 }}
                >
                  <div class="f_title">
                    <h3 style={{ textTransform: "uppercase" }}>
                      {t("englandContact")}
                    </h3>
                  </div>
                  <ul class="address_link">
                    <li>
                      <span>{t("address")} :</span> 8 Marine Parade. NR30 3AH{" "}
                      <br />
                      Great Yarmouth / Norfolk <br />
                      United Kingdom - England
                    </li>
                    <li>
                      <span>{t("phone")} :</span>
                      <a href="#">+44 4788 529 9157</a>
                    </li>
                  </ul>
                </aside>
              </div>
              <div class="col-md-3 col-sm-3">
                <aside
                  class="f_widget widget3 newsletter_w"
                  style={{ height: 338 }}
                >
                  <div class="f_title">
                    <h3>{t("socialMedia")}</h3>
                  </div>
                  <form class="mailchimp" method="post">
                    <div class="input-group subscribe_form">
                      <input
                        type="email"
                        class="form-control memail"
                        placeholder={t("email")}
                      />
                      <button class="btn btn-default" type="submit">
                        <i class="ti-email"></i>
                      </button>
                    </div>
                    <p class="mchimp-errmessage"></p>
                    <p class="mchimp-sucmessage"></p>
                  </form>
                  <ul class="f_social">
                    <li>
                      <a
                        href="https://www.facebook.com/Dragut-Shipping-Trading-Incorporation-1913013732264451/"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon="fa-brands fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/DragutShipping"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon="fa-brands fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/chartering.dragut/"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon="fa-brands fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/15263787/"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon="fa-brands fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer_copyright dark_footer">
        <div class="container">
          <div class="pull-left">
            <h5>
              © <a href="#">Dragut</a> 2022. {t("copyright")}.
            </h5>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
