/* eslint-disable jsx-a11y/anchor-is-valid */
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { CSSTransition } from "react-transition-group";

function Panel({ title, text, initial = false }) {
  const [isOpen, setIsOpen] = useState(initial === true ? true : false);
  return (
    <div class="panel panel-default" style={{ boxShadow: "none" }}>
      <div
        class="panel-heading"
        role="tab"
        id="choose_h_1"
        style={{ backgroundColor: "transparent", border: "none" }}
      >
        <h4 class="panel-title">
          <a
            role="button"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <span>{title}</span>
            <span style={{ float: "right" }}>
              {isOpen ? (
                <FontAwesomeIcon className="panel-icon" icon={faAngleDown} />
              ) : (
                <FontAwesomeIcon className="panel-icon" icon={faAngleRight} />
              )}
            </span>
          </a>
        </h4>
      </div>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="modal-transition-panel"
        unmountOnExit
      >
        <div
          id="choose1"
          // class={isOpen === true ? "panel-collapse open" : "panel-collapse close"}
          role="tabpanel"
          aria-labelledby="choose_h_1"
        >
          <div class="panel-body">
            <div>
              {text.split("\n").map((item) => (
                <>
                  {item}
                  <br />
                </>
              ))}
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

export default Panel;
