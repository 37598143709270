import React, { useState } from "react";
import "../assets/Slider.css"; // You can create your own styles or customize the slider appearance
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const Slider = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const goToPrevSlide = () => {
    const prevSlide = (currentSlide - 1 + slides.length) % slides.length;
    setCurrentSlide(prevSlide);
  };

  const goToNextSlide = () => {
    const nextSlide = (currentSlide + 1) % slides.length;
    setCurrentSlide(nextSlide);
  };

  return (
    <div className="slider-container">
      <div
        className="slider"
        style={{
          height: "100%",
          transform: `translateX(-${currentSlide * 100}%)`,
        }}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            {slide}
          </div>
        ))}
      </div>
      <button className="arrow left-arrow" onClick={goToPrevSlide}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </button>
      <button className="arrow right-arrow" onClick={goToNextSlide}>
        <FontAwesomeIcon icon={faAngleRight} />
      </button>
    </div>
  );
};

export default Slider;
