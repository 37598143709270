import { useTranslation } from "react-i18next";

/* eslint-disable react/jsx-no-target-blank */
function Link() {
  const { t } = useTranslation();
  return (
    <section class="kl_about_area business_about mar75">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-md-offset-1">
            <div class="kl_about_text">
              <div class="sec_b_title">
                <h2>{t("links")}</h2>
              </div>
              <br />
              <h4>
                <a href="https://www.bimco.org/" target="_blank" rel="nofollow">
                  Bimco
                </a>
              </h4>
              <h4>
                <a
                  href="https://www.balticexchange.com/"
                  target="_blank"
                  rel="nofollow"
                >
                  The Baltic Exchange
                </a>
              </h4>
              <h4>
                <a
                  href="https://www.fonasba.com/"
                  target="_blank"
                  rel="nofollow"
                >
                  Fonasba
                </a>
              </h4>
              <h4>
                <a href="http://www.asba.org" target="_blank" rel="nofollow">
                  Asba
                </a>
              </h4>
              <h4>
                <a href="http://www.ics.org.uk/" target="_blank" rel="nofollow">
                  Institute of Chartered Ship Brokers
                </a>
              </h4>
              <h4>
                <a
                  href="http://www.ics-shipping.org/"
                  target="_blank"
                  rel="nofollow"
                >
                  International Chamber of Shipping
                </a>
              </h4>
              <h4>
                <a
                  href="https://www.lloydslistintelligence.com/"
                  target="_blank"
                  rel="nofollow"
                >
                  Lloyd list
                </a>
              </h4>
              <h4>
                <a
                  href="http://www.hellenicshippingnews.com/"
                  target="_blank"
                  rel="nofollow"
                >
                  Hellenic News
                </a>
              </h4>
              <h4>
                <a
                  href="http://www.denizticaretodasi.org.tr/en-en/pages/home.aspx"
                  target="_blank"
                  rel="nofollow"
                >
                  Deniz Ticaret Odası / Turkish Chamber of Shipping{" "}
                </a>
              </h4>
              <h4>
                <a
                  href="http://www.armatorlerbirligi.org.tr/home-page"
                  target="_blank"
                  rel="nofollow"
                >
                  Türk Armatörler Birliği / Turkish Shipowners Association
                </a>
              </h4>
              <h4>
                <a href="http://www.gbd.org.tr/" target="_blank" rel="nofollow">
                  Gemi Brokerleri Derneği / Turkish Shipbrokers Association{" "}
                </a>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Link;
