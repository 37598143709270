/* eslint-disable jsx-a11y/iframe-has-title */
import { useTranslation } from "react-i18next";
import AboutUs from "./AboutUs";
import BuyArea from "../components/BuyArea";
import ChooseUs from "../components/ChooseUs";
import Gallery from "../components/Gallery";
import Modal from "../components/Modal";
import Hero from "../components/Hero";
import Form from "../components/Form";
const Home = () => {
  const { i18n } = useTranslation();
  return (
    <>
      <Hero />
      <AboutUs isHome={true} />
      <BuyArea />
      <ChooseUs />
      <Gallery />
      <Modal />
      <section class="req_contact_area">
        <div class="container">
          <div class="row">
            <div className="col-md-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.5096696004866!2d28.842583664980467!3d40.99221652930241!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa5f8206ef2eb%3A0x4387ec761b1916bf!2sDragut%20Shipping%20%26%20Consultancy%20%26Trading!5e0!3m2!1str!2sru!4v1572371885403!5m2!1str!2sru"
                width="400"
                height="300"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen=""
              ></iframe>
            </div>
            <div className="col-md-6">
              {/* <Form lang={i18n.language} /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
