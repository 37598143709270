import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/bootstrap-min.css";
import "./assets/css/navigation.css";
// import "./assets/css/settings.css";
import "./App.css";
import "./assets/responsive.css";

import Home from "./pages/Home";
import Layout from "./pages/Layout";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Fleet from "./pages/Fleet";
import Gloassary from "./pages/Gloassary";
import Research from "./pages/Research";
import Link from "./pages/Links";
import Services from "./pages/Services";
import Team from "./pages/Team";

function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/fleet" element={<Fleet />} />
            <Route path="/glossary" element={<Gloassary />} />
            <Route path="/links" element={<Link />} />
            <Route path="/research" element={<Research />} />
            <Route path="/services" element={<Services />} />
            <Route path="/team" element={<Team />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
