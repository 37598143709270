import { useTranslation } from "react-i18next";

function Gloassary() {
  const { t } = useTranslation();
  return (
    <section class="kl_about_area business_about mar75">
      <div class="container">
        <div class="row">
          <div class="col-md-11 col-md-offset-1">
            <table style={{ width: 562 }}>
              <tbody>
                <tr>
                  <td width="730">
                    <table
                      style={{
                        width: 680,
                        borderCollapse: "collapse",
                        marginLeft: 32,
                        marginRight: 0,
                        margiTop: 32,
                        marginBottom: 5,
                      }}
                      cellpadding="0"
                      cellspacing="0"
                    >
                      <tbody>
                        <tr>
                          <td width="8">&nbsp;</td>
                          <td width="722">
                            <p align="justify" style={{ color: "#888890" }}>
                              <b>
                                <font size="6">{t("shippingTerms")} </font>
                              </b>
                            </p>
                            <p align="justify" style={{ color: "#888890" }}>
                              <b>
                                <font size="4">
                                  {t("shipRent")}
                                </font>
                              </b>
                              <br />
                              &nbsp;
                            </p>
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              style={{
                                borderCollapse: "collapse",
                                width: 680,
                                height: 3620,
                              }}
                            >
                              <colgroup>
                                <col width="34" style={{ width: "26pt" }} />
                                <col width="551" style={{ width: "413pt" }} />
                              </colgroup>
                              <tbody>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      width: 92,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    colspan="3"
                                  >
                                    <b>
                                      <font color="#e80606">A</font>
                                    </b>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    colspan="2"
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        AA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        AAAA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        AARA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        ABT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        ADCOM&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        AFSPS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        AFFREIGHTMENT
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="120"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Always Afloat
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Always Accessible Always Afloat
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Amsterdam-Antwerp-Rotterdam Area
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        About
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Address Commission
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Arrival First Sea Pilot Station (Norway)
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        The hiring of a ship in whole or part
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        AFT
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        AGW
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        AHL
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        ANTHAM
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        APS
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        ARAG
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        A/S
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        ATDNSHINC
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        ATUTC
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="79"
                                    height="170"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      &nbsp;
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="153"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        At or towards the stern or rear of a
                                        ship
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        All Going Well
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Australian Hold Ladders
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Antwerp-Hamburg Range
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Arrival Pilot Station
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Amsterdam-Rotterdam--Antwerp-Ghent Range
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Alongside
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Any Time Day or Night Sundays and
                                        Holidays Included
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Actual Times Used to Count
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        B
                                      </font>
                                    </b>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="79"
                                    height="34"
                                    rowspan="4"
                                  >
                                    &nbsp;
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="17"
                                  ></td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 29,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      BAF
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="1"
                                    rowspan="2"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Bunker Adjustment Factor. A Fuel
                                        Surcharge expressed as a percentage
                                        added or subtracted from the freight
                                        amount reflecting the movement in the
                                        market place price for&nbsp;bunkers.
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  ></td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      BALLAST
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="17"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Heavy weight, often sea water, necessary
                                      for the&nbsp;stability and safety of a
                                      ship which is not carrying&nbsp;cargo.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    colspan="2"
                                    style={{
                                      height: 29,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="136"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      BAREBOAT CHTR
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="63"
                                    rowspan="2"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Bareboat Charter - Owners lease a
                                        specific ship and&nbsp;control its
                                        technical management and
                                        commercial&nbsp;operations only.
                                      </font>
                                    </p>
                                    <p>&nbsp;</p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    &nbsp;
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="79"
                                    height="330"
                                    rowspan="8"
                                  >
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        BBB
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        BDI
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        BENDS
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        BI
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        BIMCO
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        BL (1)
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="102"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Before Breaking Bulk
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Both Dates Inclusive
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Both Ends (Load &amp; Discharge Ports)
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Both Inclusive
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        The Baltic and International Maritime
                                        Council
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Bale
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      BL (2)
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="34"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      (Bill of Lading) A document signed by the
                                      carrier which&nbsp;acts as a receipt and
                                      evidence of title to the cargo.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        BM
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        BEAM
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        BOB
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        BOFFER
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        BROB
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        BSS
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        BSS 1/1
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        BT
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="59"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Beam
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        The maximum breadth of a ship
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Bunker on Board
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Best Offer
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Bunkers Remaining on Board
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Basis
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Basis 1 Port to 1 Port
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Berth Terms
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "middle",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        BUNDLING
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="20"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      This is the assembly of pieces of cargo,
                                      secured into one manageable unit.&nbsp;
                                      This is a very flexible&nbsp;description,
                                      a rule of thumb is to present cargo at
                                      a&nbsp;size easily handled by a large (20
                                      tonne) fork lift&nbsp;truck.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      BUNKERS
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="13"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Name given for vessels Fuel and Diesel Oil
                                      supplies&nbsp;(Originates from coal
                                      bunkers)
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      BWAD
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="17"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Brackish Water Arrival Draft
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        C
                                      </font>
                                    </b>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="17"
                                  ></td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        CBM
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        CBFT (or CFT)
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        CFR (or C&amp;F)
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        CHOPT
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        CHTRS
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="79"
                                    height="247"
                                    rowspan="5"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      &nbsp;
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="36"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Cubic Meter
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Cubic Feet
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Cost and Freight
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Charterers Option
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Charterers
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: "3px",
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: "1px",
                                      paddingRight: "1px",
                                      paddingTop: "1px",
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      CIF
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="20"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Cost, Insurance &amp; Freight. Seller pays
                                      all these costs&nbsp;to a nominated port
                                      or place of discharge.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      COA
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="19"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Contract of Affreightment - Owners agree
                                      to accept a&nbsp;cost per revenue ton for
                                      cargo carried on a specificnumber of
                                      voyages.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        COACP
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        COB
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        COD
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        COGSA
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        CONS
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        COP
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        CP (or C/P)
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        CPD
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        CPT
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        CQD
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        CROB
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        CRN
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        CST
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        CTR
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="121"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Contract of Affreightment Charter Party
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Close of Business
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Cash On Delivery
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Carriage of Goods by Sea Act
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Consumption
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Custom Of Port
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Charter Party
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Charterers Pay Dues
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Carriage Paid To
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Customary Quick Despatch
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Cargo Remaining on Board
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Crane
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Centistoke
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Container Fitted
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        D
                                      </font>
                                    </b>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="17"
                                  ></td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="171"
                                    colspan="2"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        DAPS
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        DDU
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        DDP
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        DEM
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        DESP
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        DET
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        DHDATSBE
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        DHDWTSBE
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        DISCH
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        DK
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        DLOSP
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        DO
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        DOLSP
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        DOP
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        DOT
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        DNRSAOCLONL
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="146"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Days all Purposes (Total days for
                                        loading &amp; discharging)
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Delivered Duty unpaid.
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Delivered Duty Paid.
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Demurrage&nbsp;
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Despatch
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Detention
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Despatch Half Demurrage on Actual Time
                                        Saved Both Ends
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Despatch Half Demurrage on Working Time
                                        Saved Both Ends
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Discharge
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Deck
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Dropping Last Outwards Sea Pilot
                                        (Norway)
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Diesel Oil
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Dropping Off Last Sea Pilot (Norway)
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Dropping Outward Pilot
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Department of Transport
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Discountless and Non-Returnable Ship
                                        and/or Cargo&nbsp;
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  ></td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="79"
                                    height="75"
                                    rowspan="4"
                                  >
                                    &nbsp;
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="17"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Lost or Not Lost
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "middle",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      DRAFT
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="25"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Depth to which a ship is immersed in
                                      water. The depth&nbsp;varies according to
                                      the design of the ship and will
                                      be&nbsp;greater or lesser depending not
                                      only on the weight of the ship and
                                      everything on board, but also on
                                      the&nbsp;density of the water in which the
                                      ship is lying.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalalign: "middle",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      DRK
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="17"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Derrick
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "middle",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      DUNNAGE
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="16"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Materials of various types, often timber
                                      or matting,&nbsp;placed among the cargo
                                      for separation, and hence&nbsp;protection
                                      from damage, for ventilation and, in the
                                      case of certain cargoes, to provide space
                                      in which the forks of a lift truck may be
                                      inserted.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    colspan="2"
                                    style={{
                                      height: 29,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="136"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      DWAT (or DWT)
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="29"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Deadweight. Weight of cargo, stores and
                                      water, i.e. the difference between
                                      lightship and loaded displacement.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        E
                                      </font>
                                    </b>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="79"
                                    height="45"
                                    rowspan="2"
                                  >
                                    &nbsp;
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="17"
                                  ></td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        EC
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        EIU
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        ELVENT
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        ETA
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        ETC
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        ETD
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        ETS
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        EXW
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="11"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        East Coast
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Even if Used
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Electric Ventilation
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Estimated Time of Arrival
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Estimated Time of Completion
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Estimated Time of Departure
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Estimated Time of Sailing
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Ex Works
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="722"
                                    colspan="3"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        F
                                      </font>
                                    </b>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="171"
                                    colspan="2"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      FAS
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="34"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Free Alongside Ship. Seller delivers goods
                                      to&nbsp;appropriate dock or terminal at
                                      port of embarkation and buyer covers costs
                                      and risks of loading.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        FD
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        FDD
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        FDIS
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        FEU
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        FHEX
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        FHINC
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="79"
                                    height="188"
                                    rowspan="8"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      &nbsp;
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="88"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Free of Despatch
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Freight Demurrage Deadfreight
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Free Discharge
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Forty foot container equivalency unit
                                        -&nbsp; Standard 40'&nbsp;Container
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Fridays/Holidays Excluded
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Fridays/Holidays Included
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      FILO
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="5"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Free In/Liner Out. Seafreight with which
                                      the shipper pays load costs and the
                                      carrier pays for discharge&nbsp;costs.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      FIO
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="15"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Free In/Out. Freight booked FIO includes
                                      the seafreight,but no loading/discharging
                                      costs, i.e. the charterer&nbsp;pays for
                                      cost of loading and discharging cargo.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      FIOS
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="1"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Free In/Out Stowed. As per FIO, but
                                      includes stowage&nbsp;costs.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      FIOT
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="12"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Free In/Out and Trimmed. As per FIOS but
                                      includes&nbsp;trimming - the leveling of
                                      bulk cargoes
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      FIOSLSD
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="6"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Free In/Out Stowed, Lashed, Secured and
                                      Dunnaged. As per FIO,but includes cost of
                                      lashing securing and dunnaging cargo to
                                      Masters satisfaction.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      FIOST
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="15"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Free In/Out and Trimmed. Charterer pays
                                      for cost of loading/discharging cargo,
                                      including stowage and&nbsp;trimming.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        FIT
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        FIW
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        FIXING
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        FLT
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        FMC
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        FMS
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="48"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Free In Trimmed
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Free In Wagon
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Chartering a Vessel
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Full Liner Terms - Shipowner pays to
                                        load and discharge the cargo
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Federal Maritime Commission - US
                                        government agency
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Fathoms - 6 feet
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colspan="2"
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="136"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      FO
                                      (IFO)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </font>{" "}
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="17"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      FuelOil/Intermediate FO
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      FOB
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="79"
                                    height="26"
                                    rowspan="2"
                                  >
                                    &nbsp;
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="1"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Free on Board. Seller sees the goods "over
                                      the ship's&nbsp;rail" on to the ship which
                                      is arranged and paid for by&nbsp;the buyer
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        FOFFER
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        FOG
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        FOQ
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        FOR
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="28"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Firm Offer
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        For Our Guidance
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Free On Quay
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Free On Rail
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    colspan="2"
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="136"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      FORCE MAJEURE
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="17"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Clause limiting responsibilities of the
                                      charterers,&nbsp;shippers and receivers
                                      due to events beyond their&nbsp;control.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        FOT
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        FOW (1)
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        FOW (2)
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        FREE OUT
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        FWAD
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        FWDD
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        FYG
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        FYI
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="79"
                                    height="67"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      &nbsp;
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="67"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Free On Truck
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        First Open Water
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Free On Wharf
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Free of discharge costs to Owners
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Fresh Water Arrival Draft
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Fresh Water Departure Draft
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        For Your Guidance
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        For Your Information
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="722"
                                    colspan="3"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        G
                                      </font>
                                    </b>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        GA
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        GLS
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        GNCN
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        GN (or GR)
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        GO
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        GRD
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        GRT
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        GSB
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        GSP
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        GTEE
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="79"
                                    height="85"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      &nbsp;
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="85"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        General Average
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Gearless
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Gencon&nbsp; - a standard BIMCO charter
                                        party form
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Grain (capacity)
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Gas Oil
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Geared
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Gross Registered Tonnage
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Good Safe Berth
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Good Safe Port
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Guarantee
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="722"
                                    colspan="3"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        H
                                      </font>
                                    </b>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        2H
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        HA
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        HDWTS
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        HMS
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        HO
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        HW
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="79"
                                    height="53"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      &nbsp;
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="53"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Second Half
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Hatch
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Half Despatch Working Time Saved
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Heavy Metal Scrap
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Hold
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        High Water
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="722"
                                    colspan="3"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        I
                                      </font>
                                    </b>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="171"
                                    colspan="2"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        IMDG
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        IMO
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        IND
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        INTERMODAL
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        ITF
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        IU
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        IUATUTC
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        IUHATUTC
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        IWL
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="102"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        International Maritime Dangerous Goods
                                        Code
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        International Maritime Organisation
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Indication
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Carriage of a commodity by different
                                        modes of transport, i.e. sea, road, rail
                                        and air within a single journey.
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        International Transport Federation -
                                        international body that regulates
                                        crewing of ships
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        If Used
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        If Used, Actual Time Used To Count
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        If Used, Half Actual Time Used To Count
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Institute Warranty Limits
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="722"
                                    colspan="3"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        L
                                      </font>
                                    </b>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="171"
                                    colspan="2"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      LANE METER
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="14"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      A method of measuring the space capacity
                                      of Ro/Ro ships whereby each unit of space
                                      (Linear Meter) is represented width.
                                    </font>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="171"
                                    colspan="2"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        LASH (1)
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        LASH (2)
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        LAT
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        LOA
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        LOW
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        LS (or LUMPS)
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        LSD
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        LT
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        LW
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        LYCN
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="153"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        To hold goods in position by use of
                                        Ropes, Wires, Chains or Straps etc.
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Lighter Aboard Ship - a vessel that
                                        loads small barges&nbsp;direct from the
                                        water
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Latitude
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Length Overall of the vessel
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Last Open Water
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Lumpsum
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Lashed Secured Dunnaged
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Liner Terms
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Low Water
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Laycan (Layday Cancelling Date)
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="722"
                                    colspan="3"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        M
                                      </font>
                                    </b>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="171"
                                    colspan="2"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        MB
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        MDO (DO)
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        MIN/MAX
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        MOLCHOPT
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        MOLOO
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        MT
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        M/V
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="26"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Merchant Broker
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Marine Diesel Oil
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Minimum/Maximum (cargo quantity)
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        More or Less Charterers Option
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        More or Less Owners Option
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Metric Tonne (i.e. 1,000 kilos /
                                        2204.6lbs)
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Motor Vessel
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="722"
                                    colspan="3"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        N
                                      </font>
                                    </b>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="171"
                                    colspan="2"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        NAABSA
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        NCB
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        NESTING
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        NON-REVERSIBLE
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="45"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Not Always Afloat But Safely Aground
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        National Cargo Bureau
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Implies that cargo is presented stacked
                                        in the contour of similarly shaped
                                        cargo, it may be likened to a stack of
                                        plates.
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        then saved days will not be added to
                                        discharge time&nbsp;
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="86" style={{ height: "64.5pt" }}>
                                  <td
                                    style={{
                                      height: 86,
                                      width: 92,
                                      textAlign: "left",
                                      verticalAlign: "middle",
                                      whiteSpace: "normal",
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    colspan="2"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      (Detention). If loading completed sooner
                                      than expected,&nbsp;
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="86"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      allowed.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        NOR
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        NRT
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        NYPE
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="79"
                                    height="51"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      &nbsp;
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="51"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Notice of Readiness
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Net Registered Tonnage
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        New York Produce Exchange
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="722"
                                    colspan="3"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        O
                                      </font>
                                    </b>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="171"
                                    colspan="2"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        OO
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        OSH
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        OWS
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="1"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Owners Option
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Open Shelter Deck
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Owners
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="722"
                                    colspan="3"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        P
                                      </font>
                                    </b>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        PASTUS
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        PC
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        PCGO
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        PCT
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        PDPR
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        PERDIEM
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        PHPD
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        PRATIQUE
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="79"
                                    height="68"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      &nbsp;
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="68"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Past Us
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Period of Charter
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Part Cargo
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Percent
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Per Day Pro Rata
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Per Diem - By the Day
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Per Hatch Per Day
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        License or permission to use a port
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="722"
                                    colspan="3"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        R
                                      </font>
                                    </b>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="171"
                                    colspan="2"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      RCVR
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="17"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Receivers
                                    </font>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="722"
                                    colspan="3"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      REVERSIBLE
                                      (Detention)&nbsp;&nbsp;&nbsp;&nbsp; If
                                      loading completed sooner than expected at
                                      load port, then days saved can be
                                      added&nbsp; to discharge operations.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      ROB
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="79"
                                    height="241"
                                    rowspan="8"
                                  >
                                    &nbsp;
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="17"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Remaining On Board
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 43,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      RT
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="43"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Revenue Tonne (i.e. 1.0 metric Tonne or
                                      1.0 cubic meter,whichever greater).&nbsp;
                                      The overall RT is calculated on a line by
                                      line basis of the Packing List using the
                                      largest amount. The overall freight
                                      liability is calculated on the total RT
                                      amount,&nbsp; multiplied by the&nbsp;
                                      freight rate.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        S
                                      </font>
                                    </b>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="17"
                                  ></td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        SATPM
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        SB
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        SD (or SID)
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="24"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Saturday P.M.
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Safe Berth
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Single Decker
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 29,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      SEAFREIGHT
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="29"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Costs charged for transporting goods over
                                      the sea. This does not cover any haulage
                                      or loading/discharging costs but the sea
                                      transport only.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        SELFD
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        SF
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        SHINC
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        SHEX
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="19"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Self Discharging
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Stowage factor. Cubic space occupied by
                                        one ton (2,240 lbs/1,000 kgs) of cargo.
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Sundays/Holidays Included
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Sundays/Holidays Excluded
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 29,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      SKIDS
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="29"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Are bearers (timber or steel) positioned
                                      under cargo to enable fork lift handling
                                      at port, and for ease of rigging and
                                      lashing on board ship.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        SL
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        SOC
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        SOF
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        SP
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        SRBL
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="29"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Bale (capacity)
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Shipper Owned Container
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Statement Of Facts
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Safe Port
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Signing and Releasing Bill of Lading
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    colspan="2"
                                    style={{
                                      height: 29,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      SSHEX (or SATSHEX)
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="29"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Saturdays, Sundays, Holidays Excluded
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="171"
                                    colspan="2"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      SSHINC&nbsp;&nbsp; (or SATSHINC)
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="17"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Saturdays, Sundays, Holidays Included
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      STABILITY
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="79"
                                    height="44"
                                    rowspan="2"
                                  >
                                    &nbsp;
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="15"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      It is paramount that a vessel is stable in
                                      all respects at all times. When cargo is
                                      loaded/discharged, the stability is
                                      monitored by a computer, which takes
                                      into&nbsp;vessel.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="92"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        STARBOARD
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        STEM
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        STERN
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        SUB
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="29"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Right side of a ship when facing the bow
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Subject To Enough Merchandise
                                        (Availability of Cargo)
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        The aft part of a ship
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Subject (to)
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    colspan="2"
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="136"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      SUPERCARGO
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="1"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Person employed by a ship owner, shipping
                                      company,&nbsp;charterer of a ship or
                                      shipper of goods to supervise&nbsp;cargo
                                      handling operations. Often called a port
                                      captain.
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="171"
                                    colspan="2"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        SWAD
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    ></font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        SWDD
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="14"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Salt Water Arrival Draft
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    ></font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Salt Water Departure Draft
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="722"
                                    colspan="3"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        T
                                      </font>
                                    </b>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="171"
                                    colspan="2"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        TC
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        TEU
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        TTL
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        TW
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="34"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Time Charter - Owners agree to hire a
                                        particular ship&nbsp;for a set length of
                                        time
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Twenty Foot Equivalency Unit - Standard
                                        20' Container
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Total
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Tween Decker
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="171"
                                    colspan="2"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        U
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        USC
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        UU
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        UUIUATUTC
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="23"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Unless Sooner Commenced
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Unless Used
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Unless Used If Used Actual Time Used To
                                        Count
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="722"
                                    colspan="3"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        V
                                      </font>
                                    </b>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="171"
                                    colspan="2"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      VPD
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="17"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Vessel Pays Dues
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="722"
                                    colspan="3"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        W
                                      </font>
                                    </b>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="171"
                                    colspan="2"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        WCCON
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        WIBON
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        WIFPON
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        WIPON
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="32"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Whether Customs Cleared Or Not
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Whether In Berth Or Not
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Whether In Free Pratique or not
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Whether In Port Or Not
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    colspan="2"
                                    style={{
                                      height: 29,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="136"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      WLTOHC (distance)&nbsp;
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="29"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      Water Line-To-Hatch Coaming
                                    </font>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 1,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="171"
                                    colspan="2"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        WOG
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        WPD
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        WWD
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        WRIC
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        WWR
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        WWWW
                                      </font>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="19"
                                  >
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Without Guarantee
                                      </font>
                                    </p>
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Weather Permitting Day
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Weather Working Day
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        Wire Rods In Coils
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        When, Where Ready
                                      </p>
                                    </font>
                                    <p
                                      style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                      <font
                                        color="#000000"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Wibon, Wccon, Wifpon, Wipon
                                      </font>
                                    </p>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="722"
                                    colspan="3"
                                  >
                                    <b>
                                      <font
                                        color="#e80606"
                                        face="Verdana"
                                        style={{ fontSize: "9pt" }}
                                      >
                                        Y
                                      </font>
                                    </b>
                                  </td>
                                </tr>
                                <tr height="17" style={{ height: "12.75pt" }}>
                                  <td
                                    style={{
                                      height: 17,
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="171"
                                    colspan="2"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      YAR
                                    </font>
                                  </td>
                                  <td
                                    style={{
                                      color: "windowtext",
                                      fontSize: "10.0pt",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      textDecoration: "none",
                                      fontFamily: "Arial",
                                      textAlign: "general",
                                      verticalAlign: "bottom",
                                      whiteSpace: "nowrap",
                                      border: "medium none",
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                    }}
                                    width="551"
                                    height="17"
                                  >
                                    <font
                                      color="#000000"
                                      face="Verdana"
                                      style={{ fontSize: "9pt" }}
                                    >
                                      York Antwerp Rules
                                    </font>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <p></p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Gloassary;
