import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function BuyArea() {
  const { t } = useTranslation();
  return (
    <section
      class="buy_area dzsparallaxer auto-init dzsprx-readyall"
      data-options='{direction: "reverse"}'
      style={{ height: 520 }}
    >
      <div
        class="divimage dzsparallaxer--target "
        style={{
          width: "101%",
          height: 600,
          backgroundImage: "url('img/blue-sky.jpg')",
          transform: "translate3d(0px, -44.9587px, 0px)",
        }}
      ></div>
      <div class="container">
        <div class="buy_inner_content">
          <h5>{t("buyAreaHeading1")}</h5>
          <h4>{t("buyAreaHeading2")}</h4>
          <Link class="l_btn" to="/contact-us">
            {t("buyAreaBtn")}
          </Link>{" "}
        </div>
      </div>
    </section>
  );
}

export default BuyArea;
