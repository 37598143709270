import { useTranslation } from "react-i18next";
import Panel from "./Panel";

function ChooseUs() {
  const { t } = useTranslation();
  return (
    <section
      class="choose_us_area"
      style={{ paddingTop: 70, paddingRight: 10 }}
    >
      <div class="choose_left">
        {" "}
        <img src="img/choos/choose-1.jpg" alt="" class="firefox" />{" "}
      </div>
      <div class="choose_right">
        <div class="choose_right_inner">
          <div class="full_left_sec_title">
            <h2>{t("services")}</h2>
          </div>
          <div className="choose_collaps">
            <div className="panel-group" id="accordion">
              <Panel
                title={t("charteringHeader")}
                text={t("charteringText")}
                initial={true}
              />
              {[
                ["charteringHeader2", "charteringText2"],
                ["commodityHeader", "commodityText"],
                ["commercialHeader", "commercialText"],
                ["saleHeading", "saleText"],
                ["maritimeHeading", "maritimeText"],
                ["legalHeading", "legalText"],
              ].map((item) => (
                <Panel title={t(item[0])} text={t(item[1])} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ChooseUs;
