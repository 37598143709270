import React from "react";
import "../assets/css/layers.css";
import { useTranslation } from "react-i18next";

function Hero() {
  const { t } = useTranslation();
  return (
    <section class="main_slider_area">
      <div id="business_slider" class="rev_slider" data-version="5.3.1.6">
        <ul>
          <li
            data-index="rs-1595"
            data-transition="slotzoom-horizontal"
            data-slotamount="10"
            data-masterspeed="1000"
            data-fsmasterspeed="1000"
            style={{ color: "#fff", textAlign: "center" }}
          >
            {" "}
            <img
              src="img/slider/b-slider-1.jpg"
              data-bgparallax="7"
              alt=""
              data-bgposition="center center"
              data-bgfit="cover"
              data-bgrepeat="no-repeat"
              class="rev-slidebg"
            />
            <div class="slider_text_box"></div>
            <div
              class="tp-caption first_text"
              data-width="none"
              data-height="none"
              data-whitespace="nowrap"
              data-x="['center']"
              data-y="top"
              data-voffset="['180','320','140','80','30']"
              data-hoffset="['0,'0','0','0','0']"
              data-fontsize="['28','28','28','28','28']"
              data-lineheight="['40','40','40','40','38']"
              data-transform_idle="o:1;"
              data-transform_in="x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
              data-start="1000"
              data-splitin="chars"
              data-splitout="none"
              data-responsive_offset="on"
            ></div>
            <div
              class="tp-caption secand_text"
              data-x="['center']"
              data-y="top"
              data-voffset="['385','385','305','240','230']"
              data-fontsize="['60','60','50','35','25']"
              data-lineheight="['70','70','70','50','75']"
              data-width="['none']"
              data-height="none"
              data-whitespace="nowrap"
              data-transform_idle="o:1;"
              data-transform_in="x:[105%];z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power2.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:50px;y:0px;s:inherit;e:inherit;"
              data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
              data-textAlign="['center']"
              data-start="500"
              data-splitout="none"
              data-responsive_offset="on"
              data-elementdelay="0.05"
            >
              Dragut Shipping & Trading Incorporation
            </div>
            <div
              class="tp-caption third_text"
              data-x="['center']"
              data-y="top"
              data-voffset="['485','470','400','310','300']"
              data-fontsize="['20','20','18','18','18','15']"
              data-lineheight="['20','20','20','20','20','20','18']"
              data-width="['820','840','820','600','450','350','380']"
              data-height="none"
              data-transform_idle="o:1;"
              data-whitespace="normal"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:0px;y:[100%];s:inherit;e:inherit;"
              data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
              data-start="800"
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
              data-elementdelay="0.05"
            >
              <p style={{ fontSize: 20 }}>{t("trustWorthy")}</p>
              <p style={{ fontSize: 20 }}>{t("dynamic")}</p>
            </div>
            <div
              class="tp-caption button"
              data-x="['center']"
              data-y="top"
              data-voffset="['590','570','490','420','420','440','490']"
              data-hoffset="['0','0','0','0']"
              data-fontsize="['20','20','20','20','20']"
              data-lineheight="['20','20','20','20','20']"
              data-width="none"
              data-height="none"
              data-whitespace="nowrap"
              data-frames='[{"from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":2500,"to":"o:1;","delay":500,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"x:[-100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
              data-textAlign="['left','left','left','center']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              data-start="1500"
              data-splitin="on"
              data-splitout="none"
              data-responsive_offset="on"
            >
              {" "}
              <a class="l_btn" href="/contact-us">
                {t("contactUs")}
              </a>{" "}
            </div>
          </li>
          <li
            data-index="rs-1596"
            data-transition="slotzoom-horizontal"
            data-slotamount="10"
            data-masterspeed="1000"
            data-fsmasterspeed="1000"
            style={{ color: "#fff" }}
          >
            {" "}
            <img
              src="img/slider/home-slider-1.jpg"
              data-bgparallax="7"
              alt=""
              data-bgposition="center center"
              data-bgfit="cover"
              data-bgrepeat="no-repeat"
              class="rev-slidebg"
            />
            <div class="slider_text_box"></div>
            <div
              class="tp-caption first_text"
              data-width="none"
              data-height="none"
              data-whitespace="nowrap"
              data-x="['center']"
              data-y="top"
              data-voffset="['320','320','240','200','200']"
              data-hoffset="['0,'0','0','0','0']"
              data-fontsize="['32','32','32','30','23']"
              data-lineheight="['40','40','40','40','38']"
              data-transform_idle="o:1;"
              data-transform_in="x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
              data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
              data-start="1000"
              data-splitin="chars"
              data-splitout="none"
              data-responsive_offset="on"
            ></div>
            <div
              class="tp-caption secand_text"
              data-x="['center']"
              data-y="top"
              data-voffset="['385','385','305','260','230']"
              data-fontsize="['60','60','50','35','25']"
              data-lineheight="['70','70','70','50','75']"
              data-width="none"
              data-height="none"
              data-transform_idle="o:1;"
              data-whitespace="nowrap"
              data-transform_in="x:[105%];z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power2.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:50px;y:0px;s:inherit;e:inherit;"
              data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
              data-textAlign="['center']"
              data-start="500"
              data-splitout="none"
              data-responsive_offset="on"
              data-elementdelay="0.05"
            >
              {t("reliable")}
            </div>
            <div
              class="tp-caption third_text"
              data-x="['center']"
              data-y="top"
              data-voffset="['485','470','400','325','300']"
              data-fontsize="['20','20','18','18','18','15']"
              data-lineheight="['20','20','20','20','20','20','18']"
              data-width="['820','840','820','600','450','350','380']"
              data-height="none"
              data-transform_idle="o:1;"
              data-whitespace="normal"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:0px;y:[100%];s:inherit;e:inherit;"
              data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
              data-start="800"
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
              data-elementdelay="0.05"
            >
              <p></p>
            </div>
            <div
              class="tp-caption button"
              data-x="['center']"
              data-y="top"
              data-voffset="['590','570','490','450','450',440','490']"
              data-hoffset="['0','0','0','0']"
              data-fontsize="['20','20','20','20','20']"
              data-lineheight="['20','20','20','20','20']"
              data-width="none"
              data-height="none"
              data-whitespace="nowrap"
              data-frames='[{"from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":2500,"to":"o:1;","delay":500,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"x:[-100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
              data-textAlign="['left','left','left','center']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              data-start="1500"
              data-splitin="on"
              data-splitout="none"
              data-responsive_offset="on"
            >
              {" "}
            </div>
          </li>
          <li
            data-index="rs-1597"
            data-transition="slotzoom-horizontal"
            data-slotamount="10"
            data-masterspeed="1000"
            data-fsmasterspeed="1000"
            style={{ fontWeight: 600 }}
          >
            {" "}
            <img
              src="img/slider/home-slider-2.jpg"
              data-bgparallax="7"
              alt=""
              data-bgposition="center center"
              data-bgfit="cover"
              data-bgrepeat="no-repeat"
              class="rev-slidebg"
            />
            <div class="slider_text_box"></div>
            <div
              class="tp-caption first_text"
              data-width="none"
              data-height="none"
              data-whitespace="nowrap"
              data-x="['center']"
              data-y="top"
              data-voffset="['320','320','240','180','200']"
              data-hoffset="['0,'0','0','0','0']"
              data-fontsize="['32','32','32','30','23']"
              data-lineheight="['40','40','40','40','38']"
              data-transform_idle="o:1;"
              data-transform_in="x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
              data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
              data-start="1000"
              data-splitin="chars"
              data-splitout="none"
              data-responsive_offset="on"
            ></div>
            <div
              class="tp-caption secand_text"
              data-x="['center']"
              data-y="top"
              data-voffset="['385','385','305','240','230']"
              data-fontsize="['60','60','50','35','25']"
              data-lineheight="['70','70','70','50','75']"
              data-width="['none']"
              data-height="none"
              data-whitespace="nowrap"
              data-transform_idle="o:1;"
              data-transform_in="x:[105%];z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power2.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:50px;y:0px;s:inherit;e:inherit;"
              data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
              data-textAlign="['center']"
              data-start="500"
              data-splitout="none"
              data-responsive_offset="on"
              data-elementdelay="0.05"
              style={{ color: "#000000" }}
            >
              {t("weLoveOur")}
            </div>
            <div
              class="tp-caption third_text"
              data-x="['center']"
              data-y="top"
              data-voffset="['485','470','400','310','300']"
              data-fontsize="['20','20','18','18','18','15']"
              data-lineheight="['20','20','20','20','20','20','18']"
              data-width="['820','840','820','600','450','350','380']"
              data-height="none"
              data-transform_idle="o:1;"
              data-whitespace="normal"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:0px;y:[100%];s:inherit;e:inherit;"
              data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
              data-start="800"
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
              data-elementdelay="0.05"
            >
              <p></p>
            </div>
            <div
              class="tp-caption button"
              data-x="['center']"
              data-y="top"
              data-voffset="['590','570','490','420','420','440','490']"
              data-hoffset="['0','0','0','0']"
              data-fontsize="['20','20','20','20','20']"
              data-lineheight="['20','20','20','20','20']"
              data-width="none"
              data-height="none"
              data-whitespace="nowrap"
              data-frames='[{"from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":2500,"to":"o:1;","delay":500,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"x:[-100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'
              data-textAlign="['left','left','left','center']"
              data-paddingtop="[0,0,0,0]"
              data-paddingright="[0,0,0,0]"
              data-paddingbottom="[0,0,0,0]"
              data-paddingleft="[0,0,0,0]"
              data-start="1500"
              data-splitin="on"
              data-splitout="none"
              data-responsive_offset="on"
            >
              {" "}
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Hero;
