import { useTranslation } from "react-i18next";
import Slider from "../components/Slider";

function Fleet() {
  const slides = [
    <img src="img/do-slider/gemi1.jpeg" alt="Slide 1" />,
    <img src="img/do-slider/gemi2.jpeg" alt="Slide 2" />,
    <img src="img/do-slider/gemi1.jpeg" alt="Slide 1" />,
    <img src="img/do-slider/gemi2.jpeg" alt="Slide 2" />,
  ];
  const slides2 = [
    <img src="img/do-slider/bozat3.jpeg" alt="Slide 1" />,
    <img src="img/do-slider/bozat4.jpeg" alt="Slide 2" />,
    <img src="img/do-slider/bozat3.jpeg" alt="Slide 1" />,
    <img src="img/do-slider/bozat4.jpeg" alt="Slide 2" />,
  ];
  const slides3 = [
    <img src="img/do-slider/tuna1.jpg" alt="Slide 1" />,
    <img src="img/do-slider/tuna2.jpeg" alt="Slide 1" />,
    <img src="img/do-slider/tuna3.jpeg" alt="Slide 1" />,
    <img src="img/do-slider/tuna4.jpeg" alt="Slide 1" />,
  ];
  const slides4 = [
    <img src="img/do-slider/gemi3.png" alt="Slide 1" />,
    <img src="img/do-slider/gemi3.png" alt="Slide 1" />,
    <img src="img/do-slider/gemi3.png" alt="Slide 1" />,
  ];

  const { t } = useTranslation();

  return (
    <>
      <section class="kl_about_area business_about mar75">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-md-offset-1">
              <div class="kl_about_text">
                <div class="sec_b_title">
                  <h2>{t("fleet").toLocaleUpperCase()}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="kl_about_area business_about mar75">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-md-offset-1">
              <div class="kl_about_text">
                <div class="sec_b_title">
                  <h3>TUNA</h3>
                  <p>
                    MV TUNA
                    <br />
                    7065 DWT on 6.86 MT SSW
                    <br />
                    GRAIN BALE: 320000 CBFT- BOX HOLD- 2HA/2HO
                    <br />
                    BOX/ DOUBLE SKIN -- 2000 BLT GERMANY(Ferus Smit)
                    <br />
                    CLASS: BV / FLAG: BARBADOS / IMO 9213882
                    <br />
                    LOA: 111,75 mt / BEAM: 14,95 mt
                    <br />
                    GRT: 4503 – NT: 2526
                    <br />
                    GEARLESS / SID /APP B
                    <br />
                    ALL ABT/WOG
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <Slider slides={slides3} />
            </div>
          </div>
        </div>
      </section>

      <section class="kl_about_area business_about mar75">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-md-offset-1">
              <div class="kl_about_text">
                <div class="sec_b_title">
                  <h3>FOCHA</h3>
                  <p>
                    MV FOCHA - IMO NO: 9054963
                    <br />
                    4400 DWT GENERAL CARGO VESSEL
                    <br />
                    FLAG: PANAMA
                    <br />
                    1993 BLT NETHERLAND <br />
                    GRT 2561 / NRT 1289
                    <br />
                    HOLDS CAP: 196042 CBFT
                    <br />
                    BOX/ DOUBLE SKIN
                    <br />
                    CLASS: POLSKI (IACS){" "}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-5">
              <Slider slides={slides} />
            </div>
          </div>
        </div>
      </section>
      <section class="kl_about_area business_about mar75">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-md-offset-1">
              <div class="kl_about_text">
                <div class="sec_b_title">
                  <h3>BOZAT</h3>
                  <p>
                    M/V BOZAT (tbn) (ex Baltic Sprinter) <br />
                    2377 DWT ON 4,34 mt ssw <br />
                    BOX/DOUBLE SKIN 1992 BLY GERMANY (Rosslauer) <br />
                    SELF DISCHARGER - EXCAVATOR <br />
                    FLAG: PANAMA /&nbsp; IMO 9030462 <br />
                    GRT: 1512 – NT: 696 <br />
                    LOA: 74.94 mt / BEAM: 11.40 mt <br />
                    APP B FITTED <br />
                    ALL ABT/WOG{" "}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-5">
              <Slider slides={slides2} />
            </div>
          </div>
        </div>
      </section>

      <section class="kl_about_area business_about mar75">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-md-offset-1">
              <div class="kl_about_text">
                <div class="sec_b_title">
                  <h3>TBN </h3>
                  <p>
                    MV TBN
                    <br />
                    IMO NO
                    <br />
                    5400 DWT GENERAL CARGO VESSEL
                    <br />
                    FLAG: <br />
                    Grt : 2800
                    <br />
                    1996 BLT NETHERLAND <br />
                    BOX/ DOUBLE SKIN
                    <br />
                    CLASS: IACS{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <Slider slides={slides4} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Fleet;
