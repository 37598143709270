/* eslint-disable jsx-a11y/anchor-is-valid */
import Slider from "../components/Slider";
import { useTranslation } from "react-i18next";
import Panel from "../components/Panel";

function AboutUs({ isHome = false }) {
  const slides = [
    <img src="img/do-slider/slider-6.jpg" alt="Slide 1" />,
    <img src="img/do-slider/slider-7.jpg" alt="Slide 2" />,
    <img src="img/do-slider/slider-7.jpg" alt="Slide 2" />,
  ];
  const { t } = useTranslation();

  return (
    <>
      <section className="kl_about_area business_about mar75">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-md-offset-1">
              <div className="kl_about_text">
                <div className="sec_b_title">
                  <h2>{t("aboutUs")}</h2>
                </div>
                <div>
                  {t("aboutUsText")
                    .split("\n")
                    .map((item) => (
                      <p>{item}</p>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-md-5">
              {isHome ? (
                <div class="kl_video_img">
                  {" "}
                  <img src="img/single-image1.jpg" alt="" />{" "}
                </div>
              ) : (
                <Slider slides={slides} />
              )}
            </div>
          </div>
        </div>
      </section>
      {!isHome && (
        <section class="choose_us_area business_about">
          <div class="container">
            <div class="row">
              <div class="choose_right" style={{ width: "calc(100%)" }}>
                <div class="choose_right_inner">
                  <div class="choose_collaps">
                    <div
                      class="panel-group"
                      id="accordion"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      <Panel
                        title={t("dragutTextHeader")}
                        text={t("dragutText")}
                        initial={true}
                      />
                      <Panel
                        title={t("greatSiegeHeader")}
                        text={t("greatSiegeText")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default AboutUs;
