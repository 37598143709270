/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
// Translation Hook
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function MobileMenu() {
  const { t } = useTranslation();

  const handleClose = () => {
    const mobileMenu = document.getElementsByClassName("offcanvas_menu");
    mobileMenu[0].classList.remove("open");
  };

  return (
    <>
      {" "}
      <div
        class={"offcanvas_menu right_direction"}
        style={{ overflow: "hidden", outline: "none" }}
        tabindex="0"
      >
        <div class="closer_icon" onClick={handleClose}>
          {" "}
          <i class="ti-close"></i>{" "}
        </div>
        <div class="off_menu">
          <ul class="nav mCustomScrollbar _mCS_1">
            <div
              id="mCSB_1"
              class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
              style={{ maxHeight: 230 }}
              tabindex="0"
            >
              <div
                id="mCSB_1_container"
                class="mCSB_container"
                style={{ position: "relative", top: 0, left: 0 }}
                dir="ltr"
              >
                <li>
                  <Link to="/" onClick={handleClose}>
                    {t("home")}
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" onClick={handleClose}>
                    {t("aboutUs")}
                  </Link>
                </li>

                <li>
                  <Link to="/services" onClick={handleClose}>
                    {t("services")}
                  </Link>
                </li>
                <li>
                  <Link to="/research" onClick={handleClose}>
                    {t("research")}
                  </Link>
                </li>
                <li>
                  <Link to="/fleet" onClick={handleClose}>
                    {t("fleet").toLocaleUpperCase()}
                  </Link>
                </li>
                <li>
                  <Link to="/links" onClick={handleClose}>
                    {t("links")}
                  </Link>
                </li>
                <li>
                  <Link to="/glossary" onClick={handleClose}>
                    {t("glossary").toLocaleUpperCase()}
                  </Link>
                </li>
                <li>
                  <Link to="/team" onClick={handleClose}>
                    {t("team").toLocaleUpperCase()}
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us" onClick={handleClose}>
                    {t("contact").toLocaleUpperCase()}
                  </Link>
                </li>
              </div>
              <div
                id="mCSB_1_scrollbar_vertical"
                class="mCSB_scrollTools mCSB_1_scrollbar mCS-light mCSB_scrollTools_vertical"
                style={{ display: "block" }}
              >
                <div class="mCSB_draggerContainer">
                  <div
                    id="mCSB_1_dragger_vertical"
                    class="mCSB_dragger"
                    style={{
                      position: "absolute",
                      minHeight: 30,
                      display: "block",
                      height: 110,
                      maxHeight: 220,
                      top: 0,
                    }}
                  >
                    <div
                      class="mCSB_dragger_bar"
                      style={{ lineHeight: 30 }}
                    ></div>
                  </div>
                  <div class="mCSB_draggerRail"></div>
                </div>
              </div>
            </div>
          </ul>
        </div>
        <ul class="of_social">
          <li>
            <a
              href="https://www.facebook.com/Dragut-Shipping-Trading-Incorporation-1913013732264451/"
              target="_blank"
            >
              <FontAwesomeIcon icon="fa-brands fa-facebook-f" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/DragutShipping" target="_blank">
              <FontAwesomeIcon icon="fa-brands fa-twitter" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/chartering.dragut/"
              target="_blank"
            >
              <FontAwesomeIcon icon="fa-brands fa-instagram" />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/15263787/"
              target="_blank"
            >
              <FontAwesomeIcon icon="fa-brands fa-linkedin-in" />
            </a>
          </li>
        </ul>
        <div class="copy_right_menu">
          <p>
            © <a href="#">Dragut</a> 2022. All Rights Reserved.
          </p>
        </div>
      </div>
    </>
  );
}

const Header = () => {
  // Do this two lines only when setting up the application
  const { t, i18n } = useTranslation();

  const handleClick = async (lang) => {
    await i18n.changeLanguage(lang);
  };

  const handleOpen = () => {
    const mobileMenu = document.getElementsByClassName("offcanvas_menu");
    mobileMenu[0].classList.add("open");
  };

  console.log(i18n.language);

  return (
    <>
      <MobileMenu />
      <header className="main_header_area full_sec_menu touch_menu h_pad affix-top">
        <div className="left_side">
          <Link to="/" className="logo">
            <img src="img/logo.png" alt="h_logo" />
          </Link>
        </div>
        <div className="menu">
          <ul className="nav navbar-nav">
            <li>
              <Link to="/">{t("home")}</Link>
            </li>
            <li>
              <Link to="/about-us">{t("aboutUs")}</Link>
            </li>

            <li>
              <Link to="/services">{t("services").toLocaleUpperCase()}</Link>
            </li>
            <li>
              <Link to="/research">{t("research")}</Link>
            </li>
            <li>
              <Link to="/fleet">{t("fleet").toLocaleUpperCase()}</Link>
            </li>
            <li>
              <Link to="/links">{t("links")}</Link>
            </li>
            <li>
              <Link to="/glossary">{t("glossary").toLocaleUpperCase()}</Link>
            </li>
            <li>
              <Link to="/team">{t("team").toLocaleUpperCase()}</Link>
            </li>
            <li>
              <Link to="/contact-us">{t("contact").toLocaleUpperCase()}</Link>
            </li>
          </ul>
        </div>
        <div className="right_side right_m">
          <ul>
            <li>
              <Link onClick={() => handleClick("en")}>
                <img src="img/eng.png" alt="" />
              </Link>
            </li>
            <li>
              <Link onClick={() => handleClick("tr")}>
                <img src="img/tur.png" alt="" />
              </Link>
            </li>
            <li>
              <Link to="/#" onClick={handleOpen}>
                <i className="lnr lnr-menu bar_menu"></i>
              </Link>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
};

export default Header;
