import { useTranslation } from "react-i18next";

function Team() {
  const { t } = useTranslation();
  return (
    <section class="meet_team_area mar75">
      <div class="container">
        <div class="main_title main_title_blue">
          <h2>{t("team")}</h2>

          <p>{t("teamText")}</p>
        </div>
        <div class="row m_team_items"></div>
      </div>
    </section>
  );
}

export default Team;
