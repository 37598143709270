import React from "react";
import "../assets/modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";

export default function Modal({ modal, toggleModal, src }) {
  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  return (
    <>
      <CSSTransition
        in={modal}
        timeout={600}
        classNames="modal-transition"
        unmountOnExit
      >
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <div className="close-modal" onClick={toggleModal}>
              <FontAwesomeIcon
                style={{
                  fontSize: 30,
                  float: "right",
                  marginBottom: 10,
                  cursor: "pointer",
                }}
                icon={faClose}
              />
            </div>
            <img src={src} alt="" />
          </div>
        </div>
      </CSSTransition>
    </>
  );
}
