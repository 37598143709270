import { useTranslation } from "react-i18next";
import Slider from "../components/Slider";
import Panel from "../components/Panel";

const serviceSlides = [
  <img src="img/do-slider/slider-1.jpg" alt="Slide 1" />,
  <img src="img/do-slider/slider-2.jpg" alt="Slide 2" />,
  <img src="img/do-slider/slider-3.jpg" alt="Slide 3" />,
  <img src="img/do-slider/slider-4.jpg" alt="Slide 5" />,
  <img src="img/do-slider/slider-5.jpg" alt="Slide 5" />,
  <img src="img/do-slider/slider-1.jpg" alt="Slide 1" />,
  <img src="img/do-slider/slider-2.jpg" alt="Slide 2" />,
  <img src="img/do-slider/slider-3.jpg" alt="Slide 3" />,
  <img src="img/do-slider/slider-4.jpg" alt="Slide 4" />,
  <img src="img/do-slider/slider-5.jpg" alt="Slide 5" />,
];

function Services() {
  const { t } = useTranslation();
  return (
    <section class="kl_about_area business_about mar75">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-md-offset-1">
            <div class="kl_about_text">
              <div class="sec_b_title">
                <h2>{t("services")}</h2>
                <p>{t("servicesDesc")}</p>
                <br />
              </div>
              <div class="choose_right">
                <div class="choose_right_inner">
                  <div class="full_left_sec_title"></div>
                  <div class="choose_collaps">
                    <div
                      class="panel-group"
                      id="accordion"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      <Panel
                        title={t("technicalHeader")}
                        text={t("technicalText")}
                        initial={true}
                      />
                      {[
                        ["charteringHeader", "charteringText"],
                        ["charteringHeader2", "charteringText2"],
                        ["commodityHeader", "commodityText"],
                        ["commercialHeader", "commercialText"],
                        ["saleHeading", "saleText"],
                        ["maritimeHeading", "maritimeText"],
                        ["legalHeading", "legalText"],
                      ].map((item) => (
                        <Panel title={t(item[0])} text={t(item[1])} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <Slider slides={serviceSlides} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
