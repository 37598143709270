export const aboutTr = {
  aboutUsText:
    "Dragut Denizcilik ve Ticaret’in temelleri 2014’de atılmış olup, Asya ve Avrupa’nın kucaklaştığı İstanbul’dan işletilmektedir. Ayrıca Deniz ticaretinin merkezi olan İngiltere’de de irtibat ofis bulunmaktadır.\n Kuru ve dökme yük, proje, ayrıca canlı Sürü/Hayvan taşımacılığı konusunda müşterilerine güvenilir, hızlı, kapsamlı, uzun vadeli ve kaliteli brokerlik ve işletme hizmeti vermeyi amaç edinen Dragut Denizcilik & Ticaret, bunlara ilaveten yakın müşterilerine ticari gemi işletmeciliği, yatırım, piyasa ve hukuk danışmanlığı noktasında da hizmet verebilmektedir. İngiltere Ofisi Emtia ticareti üzerine çalışmalarını sürdürmektedir.\n Firmamız; Dünya denizcilik ve ticaret piyasasında iyi bilinen bir marka haline gelmek ve itibarlı bir yer edinerek faaliyetlerine büyüyerek devam etmeyi hedef haline getirmiştir.",
  dragutTextHeader: "Dragut(1485- 23 Haziran 1565)",
  dragutText:
    "Dragut, aynı zamanda Cezayir Beyliği, Akdeniz Beylerbeyi ve önce Trablus Beyliği, daha sonra da Paşalık görevlerinde bulunmuş bir Osmanlı Türk amirali ve korsanıydı. Onun deniz komutası altında Osmanlı İmparatorluğu'nun deniz gücü Kuzey Afrika'ya yayıldı. Trablusgarp'ı fethetti. Daha sonra süsleyip inşa ettirerek Kuzey Afrika kıyılarının en etkileyici şehirlerinden biri haline getirdi.Preveze Muharebesi'nde büyük kahramanlıklar gösterdi.Ayrıca komutasındaki Deniz Kuvvetleri, ünlü Ceneviz Amirali Andrea'nın komutasındaki Kutsal İttifak donanmasını mağlup etti. Doria, Ponza Muharebesi'nde. Tüm bu ışıltılı görkemler üzerine, Osmanlı Padişahı tarafından Akdeniz'deki Osmanlı Deniz Kuvvetleri Başkomutanlığına terfi ettirildi.",
  greatSiegeHeader: "Büyük Malta Kuşatması",
  greatSiegeText:
    "Dragut'un ününü kazandığı en önemli savaş Büyük Malta Kuşatması'dır. O çağın süper gücü Osmanlı İmparatorluğu, işgal etmek ve fethetmek amacıyla Malta'yı kuşatmıştır. Ancak Hospitillaer Şövalyeleri ve Malta halkı, devasa Osmanlı karşısında büyük bir direniş göstermiştir. Ordu, kuşatmaya dayanıp onları püskürttü. Dragut, Malta kalesinden yapılan top saldırısında ölümcül şekilde yaralandı. Bir kaç gün sonra virgülün içine girdi ve öldü. Şüphesiz Dragut'un ölümü, Osmanlı İmparatorluğu'nun büyük ordusu için büyük bir kayıp oldu. .Askerlerini ilk indirdiği ve ardından öldüğü Burun, uluslararası coğrafya literatüründe ve deniz haritalarında 'Dragut Noktası' olarak anılır.",
};
export const aboutEn = {
  aboutUsText:
    "Dragut Shipping & Trading Incorporation;founded in 2014 and its head office operated from Istanbul, where Europe and Asia embrace each other.\n Also the firm has another contact office located United Kingdom, the center of shipping business.aims to provide first class service on dry cargo & bulk ship  chartering, commercial ship management, sale & purchase, maritime investment consultancy and legal consultancy to its clients and business partners involved in dry bulk shipping market.\n UK office carries on its works on commodity trading.Its core objective is to be globally well-known shipping and trading company and to take a reputable place in the World shipping community.",
  dragutTextHeader: "Dragut(1485- 23 June 1565)",
  dragutText:
    "Dragut was an Ottoman Turkish admiral and privateer who also served as Bey of Algiers; Beylerbey of the Mediterranean; and first Bey, later Pasha, of Tripoli. Under his naval command the Ottoman Empire's maritime power was extended across North Africa. He conquered Tripoli, then adorned and built up it, making it one of the most impressive cities along the North African Coast. He showed great heroism in Battle of Preveze. Also the Naval Forces under his command defeated the Holy League navy under command of famous Genoese Admiral Andrea Doria, at Battle of Ponza. Upon these all glittering glories, he was promoted to Commander-in-Chief of Ottoman Naval Forces in the Mediterranean by Sultan of the Ottoman Empire.",
  greatSiegeHeader: "Great Siege of Malta",
  greatSiegeText:
    "The most important battle where Dragut earned his reputation is Great Siege Of Malta. The Super power of that Age, Ottoman Empire laid siege on Malta in order to invade and conquer. However Knights of Hospitillaer and Maltese people showed a great resistance against huge Ottoman Army, withstood the siege and repelled them. Dragut was mortally injured during a cannon attack from a Maltese castle. He felt into comma and passed a couple of days later. No doubt, death of Dragut was a major loss for Great army of Ottoman Empire. The Cape where he landed his troops first and then died named as ”Dragut Point”in international geographical literature and marine charts",
};
export const researchTr = {
  research: "Araştırma",
  researchText:
    "Dragut Denizcilik ve Ticaret A.Ş. denizcilik pazarına yönelik araştırma çalışmalarına büyük önem vermektedir;- Etkili bir pazar istihbaratına sahip olmak için Baltık borsa raporlarını, denizcilik dergilerini ve diğer komisyoncuların yorumlarını/raporlarını takip etmek - Kısa/orta vadede müşterilerimize gönderilmek üzere kendi piyasa raporlarını yayınlamayı planlamak.",
};
export const researchEn = {
  research: "Research",
  researchText:
    "Dragut Shipping & Trading Inc. gives great importance to research studies on shipping market by;- Following Baltic exchange reports, shipping magazines and other brokers comments/reports to have an effective market intelligence - Planning to publish their own market reports in order to be sent to our clients within short/medium run.",
};
export const teamTr = {
  teamText:
    "Şirketimiz, Türkiye ve İngiltere irtibat ofislerinde bulunan deneyimli, etik, dinamik ve yetkin ekip üyeleri tarafından işletilmektedir. Ekip üyeleri yüksek eğitimlidir ve ekip liderimiz, saygın bir profesyonel olan Institute of Chartered Shipbrokers / London üyesidir. dünya çapında ticari denizcilik sektöründe faaliyet gösteren bir kuruluş.",
};
export const teamEn = {
  teamText:
    "Our company is operated by experienced, ethic, dynamic and competent team members who are located at Turkey and England contact offices. Members of Team are highly educated and our team leader is member of Institute of Chartered Shipbrokers / London, which is a prestigious professional body in commercial shipping industry worldwide.",
};
export const servicesTr = {
  servicesDesc: "Firmamız aşağıdaki konularda hizmet vermektedir.",
  technicalHeader: "Teknik ve Ticari Yönetim",
  technicalText:
    "Şirketimiz Genel, Konteyner ve Kuru yük kargo gemileri için en rekabetçi ve en kaliteli şekilde Teknik ve Ticari yönetim şekilleri sunar.\nGemilerin tüm operasyonel masraf kalemleri en optimum şekilde uzman kadromuzca elleçlenir.\nGemi yaşı, tipi ve sefer bölgesine göre Risk değerlendirmesi sonucu en uygun yönetim şekli belirlenir.\nGemilerin ticari kapasitelerini en maksimum şekilde değerlendirmek için en etkin Ticari yönetim şekli titizlikle uygulanır.",
  charteringHeader:
    "Kiralama ve Brokerlik, Kuru ve Dökme, Proje ve Genel Kargo",
  charteringText:
    "Firmamız 3000 dwt ve 70000 dwt aralığında değişen gemilere ve bu tonajlara uygun kuru ve genel yüklere uygun kiralama ve brokerlik çözümleri sunmaktadır. Kömür, tahıl, gübre, hurda, çimento ve cevher gibi dökme yüklerden, çelik, torbalı ürün ve kütük gibi yüklere kadar olan ürünler firmamızın faaliyet alanındadır. Karadeniz havzası, Avrupa, Akdeniz, Doğu ve Batı Afrika limanları, İran körfezi, Hint Okyanusu, Güney Doğu Asya, Uzak Doğu, Avustralya ve Amerika kıyılarında ki yük akışı ve açılan gemi pozisyonları dikkatle incelenerek uygun çözümler geliştirilir.\n Sadece rekabete dayanan aracı broker hizmetini amaçlamayıp, aynı zamanda yakın kiracılarla ve armatörlerle münhasır brokerlik anlaşmaları yapmayı amaç edinmekteyiz \n Firmamızın brokerlik hizmetinin kapsamı sadece iki taraf arasında arayı bulmak değil, aynı zamanda müşterilerine, bağlanan işin operasyonel ve ticari kısımlarında destek sağlamaktır. Orta ve uzun vadede firmamız gemi operatörü olarak da hareket edip kontrat ve ihalelere de katılmayı hedeflemektedir. ",
  charteringHeader2: "Kiralama - Brokerlik; Sürü & Canlı hayvan taşımacılığı",
  charteringText2:
    "Dragut Denizcilik ve Ticaret, yakın kontakları ve avantajlı coğrafi konumuyla, canlı hayvan gemisi sahiplerine ve kiracılarına birinci sınıf ve güvenilir brokerlik hizmeti sunmaktadır. ",
  commodityHeader: "Emtia Ticareti",
  commodityText:
    "İngiltere ofisimiz uluslararası dökme, parça ve paketli ürün ve emtia ticareti üzerine çalışmalarını sürdürmektedir. .",
  commercialHeader: "Ticari Gemi İşletmeciliği",
  commercialText:
    "Gemi brokeri firmalarının işini icra ederken sadece bir mesaj ileticisi ya da arabulucu olarak davranmasını değil, ayrıca müşterilerine ayrıca bağlantının tüm aşamalarında desteğini sürdürmesi gerektiğini düşünmekteyiz.\n Bu bağlamda aşağıdaki ticari işletme hizmetlerini de vermekteyiz.\n -Müşterilerle görüş alış verişinde bulunarak işin karlılık ve verimliliğini değerlendirmek.\n -Müzakereye başlamadan önce kiracının ve armatörün ticari itibarını referanslar yoluyla kontrol etmek.\n-Bağlantı sözleşmesi ve şartlar noktasında geçmiş sözleşmeler ve tecrübeler ışığında taraflara tavsiyelerde bulunmak.\n-Planlanan iş hakkında ticari ve teknik riskler noktasında değerlendirme yapıp bilgi paylaşımında bulunmak.\n -Liman veya boğaz masrafları gibi kalemleri elde ederken taraflara yardımcı olmak veya bu masrafları onlar yerine soruşturup öğrenmek.-Bağlantı sözleşmesini imzaya yada onaya hazırlamak.\n    -Astarya sürelerini ve kira dışı sürelerini hesaplamak.\n -İhtilaflı durumlarda ve davalarda yardımcı olmak, danışmanlık yapmak. ",
  saleHeading: "Gemi Alım & Satım",
  saleText:
    "Firmamız gemi alım & satım segmentinde geniş çevresini ve etkili piyasa bilgisini de kullanarak müşterilerine ve iş ortaklarına profesyonel destek sunar. .",

  maritimeHeading: "Denizcilik Yatırım Danışmanlığı",
  maritimeText:
    "Firmamız, Denizcilik sektörüne girme noktasında istekli olan yatırımcılara, tanıtım ve başlangıç adımından tüm safhalara kadar teknik ve ticari çevre ve altyapısını kullanarak danışmanlık yapmaktadır.\n Alınacak gemi tonajının, yaşını ve tipinin eldeki bütçeye göre değerlendirdikten sonra, girişimci adayları yatırıma dair gelir gider ve risk hesaplarını içeren titizlikle hazırlanmış sunum ve dokümanlarla aydınlatılır.\n Ülkemizin iş dünyasının daha çok denizciliğe yatırım yapmasını ve ticari filo muzun daha önce hiç olmadığı kadar sıklıkta Dünya denizlerinde bayrak göstermesini arzulamaktayız.\n Bunun için üstümüze düşeni yapmak babında denizciliğe ilgi duyan girişimci adaylarına herhangi bir ücret karşılığı olmadan sunum ve tanıtım hizmeti yapmaktayız. ",

  legalHeading: "Hukuk Danışmanlığı",
  legalText:
    "Firmamız, ihtilaf durumunda yakın ve münhasır müşterilerine alakalı, icra edilmiş dava dosyalarını ve sözleşme detaylarını inceleyip, bu bilgiler ışığında Hukuki danışmanlık vermektedir.\n Orta ve uzun vadede firmamız, büyümesine paralel olarak bir hukuk departmanı kurup, profesyonel uzmanlardan ve avukatlarla iştirakle bu faaliyetini gerçekleştirmeyi hedeflemektedir. ",
};
export const servicesEn = {
  servicesDesc:
    "Dragut Shipping & Trading Inc. is currently active in serving on;",
  technicalHeader: "Technical and Commercial Management",
  technicalText:
    "Our company offers the most competitive and highest quality Technical and Commercial management methods for General, Container and Dry Cargo ships.\n All operational cost items of the ships are handled in the most optimum way by our expert staff.\nThe most appropriate management method is determined as a result of the risk assessment according to the ship's age, type and voyage region.\nThe most effective commercial management method is meticulously implemented in order to maximize the commercial capacity of ships.",
  charteringHeader: "Chartering - Dry Bulk, Project, General Cargo",
  charteringText:
    "Dragut Shipping provides suitable chartering, broking and transportation solutions for bulk carrrier vessels ranging between 3000 DWT - 70000 DWT and suitable dry bulk and general cargoes that can be carried by those size vessels. Bulk cargoes such as coal, grain, scrap, cement, fertilisers and ore, general cargoes such as steel products, bagged product, timber and logs are within scope of the firm. Cargo orders and vessel positions opening at Black Sea basin, Europe, Mediterranean, East and West Africa ports, Persian gulf, Indian ocean, South East Asia, Far East, Australia and Americas coasts, are monitorised and suitable chartering solutions are develeoped.\n Dragut Shipping does not only aim to provide competitive brokering service but also plan to make agreements with close clients in order to be exclusive brokers and commercial managers of their vessels and cargoes.\n Scope of its ship brokering service is not only about solely mediating charterers and owners. It also involves to support clients by assisting on commercial and operational aspects.\n Dragut Shipping & Trading Inc. targets to act as vessel operator by chartering in vessels, making contract of affreignments and joining cargo tenders.",
  charteringHeader2: "Chartering - Livestock, Cattle",
  charteringText2:
    "Dragut Shipping is pleased to provide first class and reliable chartering & shipbroking service to Livestock & Cattle carrier owners and livestock commodity charterers worldwide by using its close connections and geographical location. ",
  commodityHeader: "Commodity Trading",
  commodityText:
    "Our UK office carries on its works on bulk, dry, bagged and packaged commodity trading. ",
  commercialHeader: "Commercial & Technical Ship management",
  commercialText:
    "We think that profession of Ship-broking should not be like just acting as an e-mail postman or a messenger.\n We believe a ship broker should be able to support his clients during whole phases of fixture by his professional skills.\n For this reason, pleased to provide commercial ship management service to our clients by being able to perform following items.\n -Assessing prolificness of employment and sharing thoughts with clients.\n    -Checking reputation of charterers or owners on behalf of the client prior starting negotiation.\n -Giving advices to owners and charterers during negotiation on charter party details by comparing other charter parties and getting support of previous experiences.\n-Assessing trade and cargo risks of planned employment and sharing collected data with clients.\n -Checking and settlement of D/A\n-Preparing soft and hard copies of charter parties just upon clean fixture and get it signed to both parties.\n-Assistance on Laytime calculations & Off-hire calculations â€¢ Assistance, consultancy or performing claim handling.",
  saleHeading: "Sale& Purchase",
  saleText:
    "Our company offers professional support on ship sale & purchase to all clients and business partners by means of its wide network and efficient market intelligence",

  maritimeHeading: "Maritime Investment Consultancy",
  maritimeText:
    "Our company assists the entrepreneurs who are keen on investing on shipping, as from introduction process till implementation phase by support of our wide commercial and technical network\n Upon determining ship size & type as per budget and market conditions, entrepreneurs are en-lighted with studiously prepared presentations and documents showing estimated costs and profit of planned investment \n We wish our local business society to be more involved in shipping investments and the merchant fleet shows the flag on all over the world much more frequent than before.\n In this sense, we would be proud to do our part by showing presentations on maritime Investment to demanding entrepreneurs with free of any charge.",

  legalHeading: "Legal Consultancy",
  legalText:
    "Our Company is pleased to give legal consultancy service to close & exclusive clients in light of related & executed maritime cases and charter party details. In the medium or long run, we plan to establish a professional legal department incorporated to Professional experts and lawyers.",
};

export const footerTr = {
  turkeyContact: "TÜRKİYE İRTİBAT ADRESİ",
  address: "Adres",
  phone: "Telefon",
  englandContact: "BİRLEŞİK KRALLIK – İNGİLTERE İRTİBAT ADRESİ",
  socialMedia: "Bizden Haberdar Olun",
  email: "E-mail Adresiniz...",
  copyright: "Tüm Haklar Saklıdır.",
};
export const footerEn = {
  turkeyContact: "TURKEY CONTACT OFFICE",
  address: "Address",
  phone: "Phone",
  englandContact: "UNITED KINGDOM - ENGLAND CONTACT OFFICE",

  socialMedia: "SOCIAL MEDIA",
  email: "E-mail",
  copyright: "All Rights Reserved.",
};

export const formTr = {
  name: "Ad-Soyad",
  email: "Email",
  number: "Telefon No",
  subject: "Konu",
  message: "Mesaj",
  verification: "Doğrulama",
  contactForm: "İletişim Formu",
};

export const formEn = {
  name: "Name",
  email: "Email",
  number: "Number",
  subject: "Subject",
  message: "Message",
  verification: "Verification",
  contactForm: "Contact Form",
};

export const buyAreaTr = {
  buyAreaHeading1: "Dragut Shipping & Trading Incorporation",
  buyAreaHeading2: "Denizcilik'te Yeni Bir Ruh",
  buyAreaBtn: "Daha Fazlası İçin",
};
export const buyAreaEn = {
  buyAreaHeading1: "Dragut Shipping & Trading Incorporation",
  buyAreaHeading2: "A New Spirit in the Shipping World",
  buyAreaBtn: "More",
};

export const heroTr = {
  weLoveOur: "İşimizi Seviyoruz..",
  reliable: "Önce Güven..",
  trustWorthy: "Güvenilir, Yetkin ve Rekabetçi",
  dynamic: "Dinamik ama Dikkatli, Çözüm Odaklı ama Titiz.",
  contactUs: "İletişim",
};

export const heroEn = {
  weLoveOur: "Wr Love Our Profession..",
  reliable: "Reliability First!!",
  trustWorthy: "Trustworthy, Competent and Competitive",
  dynamic: "Dynamic but Careful, Solution Oriented but Meticulous.",
  contactUs: "Contact Us",
};
