/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useTranslation } from "react-i18next";
import Form from "../components/Form";
import { useEffect } from "react";

function ContactUs() {
  const { t, i18n } = useTranslation();

  useEffect(() => {}, []);

  return (
    <>
      <section class="banner_area">
        <div class="container"> </div>
      </section>
      <section class="get_office_details">
        <div class="get_left_office get_text_of">
          <img src="img/dark.png" alt="" />
          <h2 style={{ padding: 5 }}>{t("turkeyContact")}</h2>
          <p>
            NISH ISTANBUL RESIDENCES. B Blok. No:145 Cobancesme Mah. Sanayi Cd.
            34196 Bahcelievler / ISTANBUL / TURKEY
          </p>
          <a href="#">chartering@dragutship.com</a>
          <br />

          <a href="#">info@dragutship.com</a>
          <br />

          <a href="#">crew@dragutship.com</a>
          <br />

          <h5>
            {" "}
            <a href="">Phone :+90 212 653 3428</a>
          </h5>
        </div>

        <div
          class="get_left_office get_text_of"
          style={{ background: "none", marginTop: -70 }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.5096696004866!2d28.842583664980467!3d40.99221652930241!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa5f8206ef2eb%3A0x4387ec761b1916bf!2sDragut%20Shipping%20%26%20Consultancy%20%26Trading!5e0!3m2!1str!2sru!4v1572371885403!5m2!1str!2sru"
            width="100%"
            height="550"
            frameborder="0"
            style={{ border: 0 }}
            allowfullscreen=""
          ></iframe>
        </div>
      </section>
      <div script="" begins="" here="">
        <Form lang={i18n.language} />
        <p>
          Powered by{" "}
          <a
            class="footerLink13"
            title="123ContactForm"
            href="http://www.dragutship.com"
          >
            Dragut Shipping
          </a>
          &nbsp;|&nbsp;
          <a
            style={{
              fontSize: "small!important",
              color: "#000000!important",
              textDecoration: "underline!important",
            }}
            title="Looks like phishing? Report it!"
            href="http://www.dragutship.com"
            rel="nofollow"
          >
            Report abuse
          </a>
        </p>
      </div>
    </>
  );
}

export default ContactUs;
