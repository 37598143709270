/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../assets/icon.css";
import React, { useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";

function ProjectItem({ img }) {
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    console.log("click");
    setModal(!modal);
  };

  return (
    <>
      <div class="l_r_project_item">
        <img src={"img/l-project/fl-l-project/" + img} alt="" />
        <a
          class="plus_project"
          onClick={toggleModal}
          // href={"img/l-project/fl-l-project/" + img}
          style={{ cursor: "pointer" }}
          data-lightbox="image-1"
        >
          <i className="ti-plus">
            <FontAwesomeIcon icon={faPlus} />
          </i>
        </a>
        <div class="l_project_hover">
          <h4></h4>
          <h5></h5>
        </div>
      </div>
      <Modal
        modal={modal}
        toggleModal={toggleModal}
        src={"img/l-project/fl-l-project/" + img}
      />
    </>
  );
}

function Gallery() {
  const { t } = useTranslation();
  return (
    <>
      <section
        class="latest_full_project"
        style={{ paddingTop: 10, paddingBottom: 10 }}
      >
        <div class="main_title">
          <h2>{t("gallery")}</h2>
        </div>
        <div class="l_right_project">
          {Array(10)
            .fill(0)
            .map((_, i) => (
              <ProjectItem img={`fl-project-${i + 1}.jpg`} />
            ))}
        </div>
      </section>
    </>
  );
}

export default Gallery;
