import { useTranslation } from "react-i18next";

function Research() {
  const { t } = useTranslation();
  return (
    <section class="kl_about_area business_about mar75">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-md-offset-1">
            <div class="kl_about_text">
              <div class="sec_b_title">
                <h2>{t("research")}</h2>
              </div>
              <p>
                {t("researchText")
                  .split("-")
                  .map((item, i) => (
                    <>
                      {(i > 0 ? "-" : "") + item}
                      <br />
                    </>
                  ))}
              </p>
            </div>
          </div>
          <div class="col-md-5">
            <div class="kl_video_img">
              {" "}
              <img src="img/single-image2.jpg" alt="" />{" "}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Research;
